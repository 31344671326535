<template>

  <div :class="'search ' + (showed ? ' showed ' : '')">
       
    <input 
      type="text" 
      @keyup="start"     
      v-click-outside="hide"
      v-model="keyword" 
      :placeholder="placeholder"
      >

    <i class="icon-close" v-if="keyword?.length" @click="remove"></i>
    <i class="icon-search" @click="start"></i>




    <div class="res">
      <div class="cats">
        <template v-for="category in categories">
          <div class="cat">
            <div class="name">
              {{ getCategoryName(category.type) }}              
            </div>
            <div class="items">
              <template
                v-for="item in category.list"
                >
                <div 
                  class="item" 
                  @click="choose(item, $event)"
                  >
                  <img class="av" v-if="item.avatar" :src="item.avatar">
                  {{ item.name }}                  
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>

  </div>
  
</template>

<script setup>
 
import debounce from 'lodash/debounce';
const emits = defineEmits(['choose'])
const props = defineProps({
  query_type: {
    type: String
  },
  placeholder: {
    type: String
  },
  val: {
    type: String
  },
})
const { t } = useI18n()
const { $api } = useNuxtApp()

var keyword = ref(props.val);
var results = ref([]);

var showed = ref(false);

const getCategoryName = function(type){   
  if(type == 'keyword') return t('universal_search_custom');
  if(type == 'brandseed') return t('universal_search_breeders');
  if(type == 'seed') return t('universal_search_strains');
  if(type == 'grower') return t('universal_search_growers');
  if(type == 'diary') return t('universal_plurals_diaries');
  if(type == 'brandnutrient') return t('universal_search_nutrients');
  if(type == 'nutrient') return t('universal_search_nutrient_products'); 
  if(type == 'brandlamp') return t('universal_search_lamp');
  if(type == 'lamp') return t('universal_search_lamp_products'); 
  if(type == 'brandtent') return t('universal_search_tent');
  if(type == 'tent') return t('universal_search_tent_products'); 
}

const categories = computed(() => {
  var list = {};
  for(var r of results.value){
    if(!list[r.type]) 
      list[r.type] = {
        type: r.type,
        list: []
      };
    list[r.type].list.push(r);
  }  
  return list;
});
 
const remove = () => {
  keyword.value = '';
  emits('search', keyword.value);
}


const hide = function(){    
  showed.value = false
}

const choose = function(item,event){    
 console.log('item');
 console.log(item);
  emits('choose', {
    tag_id: item.type + '_' + item.id,
    val: item.type == 'keyword' ? item.name : item.id,
    name: item.name,
    type: item.type,
    tag: item.tag,
    is_temp: true,        
    avatar: item.avatar, 
    not_countable: true, 
  });
  event.stopPropagation();
  keyword.value = '';
  showed.value = false;
}

const start = debounce((event) => { 
  console.log('start search');
  keyword.value = event.target.value;

  showed.value = true;      
  $api.getGlobalSearch(keyword.value)
  .then((response) => {        
    results.value = response;
  })
  .catch(function (error) { 
    
  });
  
}, 300);





</script>

<style scoped>

/* search */

.search{
  position: relative;
}
.search input{
  border-radius: 30px;
  padding: 0.4rem 3.2rem 0.4rem 0.6rem;
}
/* results */
 
 .res{
   display: none;
    position: absolute;
    z-index: 1;
    background-color: var(--un-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
    border: 1px solid rgba(34, 36, 38, 0.15);
    min-width: 360px;
    width: 100%;
    right: 0;
    overflow-y: auto;
    max-height: 500px;
 }
 .showed .res{
   display: inherit;
 }
 .cats{}
 .cats .cat{
  display: flex;
  min-width: 120px;
  width: 100%;
 }
 .cats .cat .name{
  background-color: var(--un-background-color-gray);
  padding: 0.4rem 1rem;
  font-weight: bold;
  flex-shrink: 0;
  width: 30%;
  text-transform: capitalize;
 }
 .items{
   display: flex;
   flex-direction: column;
   border-left: 1px var(--un-background-color-gray-light) solid; 
   border-top: 1px var(--un-background-color-gray-light) solid;
   width: 70%;
 }
 .items .item{
  padding: 0.4rem 1rem;
  width: calc(100% - 2rem);
  cursor: pointer;
  gap: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: bold;
    overflow-wrap: anywhere;
}
 .items .item .av{ 
    border-radius: 3px;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
    flex-shrink: 0;
}

.search .icon-close,
.search .icon-search{    
  position: absolute;
  top: 0;
  display: flex;
  width: 26px;
  height: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: gray;
}
.search .icon-close{
  right: 27px;
}
.search .icon-search{
  right: 4px;
}

</style>
